.navItem {
	display: flex;
	align-items: center;
	width: fit-content;
	color: var(--text-color);
	padding: 15px;
	margin: 0 5px;
	border-radius: 30px;
}

.navItem:hover {
	cursor: pointer;
	color: var(--main-color);
	background-color: var(--secondary-color);
	transition: all 250ms ease-in-out;
	transition-property: color, background-color;
}

.active {
	color: var(--main-color);
}

.navItem__icon {
	font-size: 30px;	
}

.navItem__title {
	font-size: 20px;
	font-weight: bold;
	padding-left: 15px;
}

@media screen and (max-width: 768px) {
	.navItem__title {
		display: none;
	}
}
