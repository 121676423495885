.post {
    display: flex;
    padding: 10px;
    color: var(--text-color);
    border-bottom: var(--border-width) solid var(--border-color);
}

.post__avatar {
    font-size: 50px;
    flex: 0;
}

.post__content {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 0 20px;
}

.post__header {
    display: flex;
    align-items: center;
}

.post__displayName {
    font-weight: bold;
    flex: 0.45;
}

.post__username {
    flex: 1;
}

.post__timestamp {
    font-style: italic;
}

.post__text {
    margin-bottom: 10px;
    max-width: 500px;
    overflow-wrap: break-word;
}

.post__image {
    max-width: 400px;
}

.post__imageSrc {
    width: 100%;
}