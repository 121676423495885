.profile {
    margin: 10px;
    display: flex;
}

.profile__avatar {
    font-size: 90px;
    width: 90px;
    height: 90px;
}


.profile__userInfo > h4 {
    margin-left: 20px;
}