.postsFeed {
	flex: 0.5;
	border-width: 0px var(--border-width);
	border-color: var(--border-color);
	border-style: solid;
}

.postsFeed__container {
	position: sticky;
	top: 0;
}

.postsFeed__header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: var(--bg-color);
	border-bottom: var(--border-width) solid var(--border-color);
	padding: 15px;
}

.postsFeed__menu {
	display: none;
	cursor: pointer;
}

.postsFeed__search {
    display: flex;
    align-items: center;
}

.postsFeed__menuIcon, .postsFeed__searchIcon {
    font-size: 25px;
}

.postsFeed__searchInput {
    padding: 10px;
    outline: none;
    border-radius: 25px;
    border: none;
    color: var(--text-color);
    background-color: var(--secondary-color);
    font-weight: bold;
    font-size: 14px;
}

@media screen and (max-width: 768px) {
	.postsFeed {
		flex: 1;
		transition: flex 300ms ease-in-out;
	}
	.postsFeed__menu {
		display: flex;
    	align-items: center;
	}
}