.navigation {
	flex: 0.2;
	display: flex;
	flex-direction: column;
}

.navigation__title {
	display: flex;
	align-items: center;
	width: fit-content;
	padding: 15px;
	border-radius: 30px;
}

.navigation__titleIcon {
	margin: 0 8px;
}

.navigation__titleIcon:hover {
	color: var(--main-color);
	transition: all 250ms ease-in-out;
	transition-property: color, background-color;
}

.navigation__titleText {
	padding-left: 10px;
}

.navigation__pages {
	position: sticky;
	top: 0;
}

.navigation__themes {
	position: sticky;
	top: calc(100vh - 225px);
}

@media screen and (max-width: 768px) {
	.navigation {
		flex: 0.1;		
	}
	.navigation__titleText {
		display: none;
	}
}