.createPost {
    background-color: var(--secondary-color);
    border-bottom: var(--border-width) solid var(--border-color);
    padding: 10px;
}

.createPost__form {
    display: flex;
    align-items: center;
}

.createPost__avatar {
    font-size: 45px;
    width: 45px;
    height: 45px;
}

.createPost__input {
    flex: 1;
    padding: 10px;
    margin: 0 20px;
    outline: none;
    border-radius: 25px;
    border: none;    
    font-weight: bold;
    font-size: 16px;
}

.createPost__button {
    flex: 0;
    padding: 12px 20px;
    border-radius: 25px;
    color: white;
    background-color: var(--main-color);
    font-weight: bold;
    font-size: 16px;
    border: none;
}

.createPost__imageURL {
    padding: 7px;
    width: 70%;
    margin-left: 12%;
    outline: none;
    border-radius: 25px;
    border: none;    
    font-weight: bold;
    font-size: 13px;
}

.createPost__button:hover {
    cursor: pointer;
    opacity: 0.75;
}