.userWidget {
    display: flex;
    align-items: center;
}

.userWidget__avatar {
    font-size: 45px;
    width: 45px;
    height: 45px;
    flex: 0.3;
}

.userWidget__info {
    display: flex;
    flex-direction: column;
    flex: 0.7;
}