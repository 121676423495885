.sidebar {
	flex: 0.2;
	display: flex;
	min-width: 200px;
	flex-direction: column;
	background-color: var(--bg-color);
}

.sidebar__header {
	position: sticky;
	top: 0;
	padding: 20px;
}

.sidebar__loginBtn {
    cursor: pointer;
    padding: 10px 15px;
	margin: 10px;
    border-radius: 25px;
    color: white;
    background-color: var(--main-color);
    font-weight: bold;
    font-size: 14px;
    border: none;
}

.sidebar__footer {
	position: sticky;
	top: calc(100vh - 100px);
	padding-left: 20px;
}

@media screen and (max-width: 768px) {
	.sidebar {
		display: none;
	}
	.sidebar__header {
		position: relative;
	}
	.open_menu {
		display: block;
		padding: 0 15px 15px 0;
		border: var(--border-width) solid var(--border-color);
		position: fixed;
		z-index: 10;
		top: 66px;
		right: 0;
		height: 600px;
		
	}
}