/* CSS variables */
:root {
	--main-color: #2377ff;
	--secondary-color: #c6e5ff;
	--border-color: grey;
	--border-width: 1px;
	--text-color: black;
	--text-alt-color: white;
	--bg-color: #fafafa;
	--bg-alt-color: #f7f9fa;
}

.theme-dark {
	--main-color: #2377ff;
	--secondary-color: #152c3f;
	--text-color: white;
	--text-alt-color: grey;
	--bg-color: #15202b;
	--bg-alt-color: #192734;
}

* {
	margin: 0;
}

body {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-transition: 200ms ease-in-out;
	-moz-transition: 200ms ease-in-out;
	transition: 200ms ease-in-out;
	color: var(--text-color);
	background-color: var(--bg-color);
}

a {
	text-decoration: none !important;
}

/* Scrollbar */
::-webkit-scrollbar {
	width: 7px;
	height: 7px;
}
::-webkit-scrollbar-thumb {
	background: var(--main-color);
	border-radius: 5px;
}
::-webkit-scrollbar-track {
	background: var(--bg-alt-color);
}
